/*! local-extensions.css */
/*! local extensions should be added here */

/* fix an issue that content is slightly hidden on mobile */
.accordion-item.is-active .accordion-content {
    padding-top: 1.5rem;
}

/* change the dropdown to white and keep the border color same on hover */
@media print, screen and (min-width: 48em) {
    .linklist-dropdown-wrapper .linklist-dropdown-expand-text-wrapper .linklist-dropdown-expand-text {
        color: #fff;
    }
    .linklist-dropdown-wrapper .linklist-dropdown-expand-text-wrapper:active, .linklist-dropdown-wrapper .linklist-dropdown-expand-text-wrapper:focus, .linklist-dropdown-wrapper .linklist-dropdown-expand-text-wrapper:hover {
        border-bottom-color: #999;
    }
    .linklist-dropdown-wrapper .open .linklist-dropdown-expand-text-wrapper {
        border-bottom-color: #262626;
    }
}
